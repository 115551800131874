import React, { useEffect } from 'react';
import {
  PaymentOption,
  BorderStyle,
  PaymentType,
  ReservedPaymentOptionLabels,
  ReservedPaymentOptionIds,
} from '../../../../../../types/types';
import {
  Dropdown,
  DropdownTheme,
  DropdownOptionProps,
} from 'wix-ui-tpa/cssVars';
import { SlotService } from '../../../../../../utils/state/types';
import { classes, st } from './ServicePaymentDropdown.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../../settingsParams';
import { ServicePaymentDropdownHooks } from './dataHooks';
import { getServiceSlotIdentifier } from '../../../../../../utils';
import { useFormActions } from '../../../../Hooks/useFormActions';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { getContent } from '../../../../../../utils/content/content';
import { SelectedPaymentOption } from '../../../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type ServicePaymentDropdownOptionProps = {
  service: SlotService;
  paymentOptions: PaymentOption[];
  label: string;
};

export const ServicePaymentDropdown: React.FC<
  ServicePaymentDropdownOptionProps
> = ({ service, paymentOptions, label }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { setServiceSelectedPaymentOption } = useFormActions();
  const [selectedId, setSelectedId] = React.useState<string>();
  const settings = useSettings();
  const theme =
    settings.get(settingsParams.fieldsBorderStyle) === BorderStyle.UNDERLINE
      ? DropdownTheme.Line
      : DropdownTheme.Box;

  const buildPaymentOptionId = (paymentOption: PaymentOption) => {
    return `${paymentOption.id}:${paymentOption.label}`;
  };
  const getPaymentOptionLabel = (paymentOption: PaymentOption) => {
    let optionLabel;
    switch (paymentOption.type) {
      case PaymentType.SINGLE_SESSION: {
        if (paymentOption.label === ReservedPaymentOptionLabels.ONLINE) {
          optionLabel = getContent({
            settings,
            settingsParam: settingsParams.payOnlineText,
          });
        } else if (
          paymentOption.label === ReservedPaymentOptionLabels.OFFLINE
        ) {
          optionLabel = getContent({
            settings,
            settingsParam: settingsParams.payOfflineText,
          });
        }
        break;
      }
      case PaymentType.BUY_PLAN: {
        optionLabel = t(
          'app.bookings-form.payment-section.service-dropdown.buy-a-plan',
          {
            planName: paymentOption.label,
          },
        );
        break;
      }
      case PaymentType.USE_PLAN: {
        optionLabel = t(
          'app.bookings-form.payment-section.service-dropdown.use-my-plan',
          {
            planName: paymentOption.label,
          },
        );
        break;
      }
      default:
        optionLabel = paymentOption.label;
    }

    return optionLabel.toString();
  };

  const options: DropdownOptionProps[] = (paymentOptions || []).map(
    (paymentOption) => {
      return {
        id: buildPaymentOptionId(paymentOption),
        value: getPaymentOptionLabel(paymentOption),
        subtitle: paymentOption.pricingDetails,
      };
    },
  );

  const findSelectedPaymentOption = (id: string) => {
    return (paymentOptions || []).find((paymentOption) => {
      const [paymentOptionId, optionLabel] = id.split(':');
      return (
        paymentOption.id === paymentOptionId &&
        paymentOption.label === optionLabel
      );
    })!;
  };

  useEffect(() => {
    const selectedPaymentOptionId = service.selectedPaymentOption.id;
    const selectedPaymentType = service.selectedPaymentType;

    const findAndSetSelectedId = (
      predicate: (value: PaymentOption) => boolean,
    ) => {
      const selectedOption = paymentOptions.find(predicate);
      if (selectedOption) {
        setSelectedId(buildPaymentOptionId(selectedOption));
      }
    };

    if (selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan) {
      findAndSetSelectedId((option) => option.type === PaymentType.BUY_PLAN);
    } else if (
      selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession
    ) {
      if (selectedPaymentType === SelectedPaymentOption.ONLINE) {
        findAndSetSelectedId(
          (option) =>
            option.type === PaymentType.SINGLE_SESSION &&
            option.label === ReservedPaymentOptionLabels.ONLINE,
        );
      } else if (selectedPaymentType === SelectedPaymentOption.OFFLINE) {
        findAndSetSelectedId(
          (option) =>
            option.type === PaymentType.SINGLE_SESSION &&
            option.label === ReservedPaymentOptionLabels.OFFLINE,
        );
      }
    } else {
      findAndSetSelectedId((option) => option.id === selectedPaymentOptionId);
    }
  }, [service.selectedPaymentOption.id, service.selectedPaymentType]);

  const onChange = async (option: DropdownOptionProps) => {
    setSelectedId(option.id);
    setServiceSelectedPaymentOption(
      service,
      findSelectedPaymentOption(option.id!),
    );
  };

  return (
    <div
      className={st(classes.root, {
        isMobile,
      })}
    >
      <Dropdown
        key={getServiceSlotIdentifier(service.nestedSlot)}
        data-hook={`${
          ServicePaymentDropdownHooks.dropdown
        }_${getServiceSlotIdentifier(service.nestedSlot)}`}
        className={st(classes.dropdown, { theme })}
        options={options}
        disabled={options.length === 1}
        initialSelectedId={selectedId}
        onChange={onChange}
        label={label}
      />
    </div>
  );
};
